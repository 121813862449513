import { CONFIG } from "./config.js"

const checkCurrentUrl = () => {
  try {
    const localurl = CONFIG.local.WEB_URL;
    const developmentUrl = CONFIG.development.WEB_URL;
    const stageUrl = CONFIG.stage.WEB_URL;
    const productionUrl = CONFIG.production.WEB_URL;
    return { localurl, developmentUrl, stageUrl, productionUrl };
  } catch (err) {
    console.log(err);
    return { developmentUrl: 5, stageUrl: -1, productionUrl: -1 };
  }
}

const CURRENT_API_URL = () => {
  try {
    const { localurl, developmentUrl, stageUrl, productionUrl } = checkCurrentUrl();
    const localAPIUrl = CONFIG.local.API_URL;
    const developmentAPIUrl = CONFIG.development.API_URL;
    const stageAPIUrl = CONFIG.stage.API_URL;
    const productionAPIUrl = CONFIG.production.API_URL;
    if (window.location.origin === localurl) {
      return localAPIUrl;
    } else if (window.location.origin === developmentUrl) {
      return developmentAPIUrl;
    } else if (window.location.origin === stageUrl) {
      return stageAPIUrl;
    } else if (window.location.origin === productionUrl) {
      return productionAPIUrl;
    } else return localAPIUrl;
  } catch (err) {
    console.log("GET CURRENT API_BASE ", err);
    return "http://localhost:9999/";
  }
};

const CURRENT_SIO_URL = () => {
  try {
    const { localurl, developmentUrl, stageUrl, productionUrl } = checkCurrentUrl();
    const localSIO = CONFIG.local.SIO_ENDPOINT;
    const developmentSIO = CONFIG.development.SIO_ENDPOINT;
    const stageSIO = CONFIG.stage.SIO_ENDPOINT;
    const productionSIO = CONFIG.production.SIO_ENDPOINT;
    if (localurl) {
      return localSIO;
    } else if (developmentUrl > 0) {
      return developmentSIO;
    } else if (stageUrl > 0) {
      return stageSIO;
    } else if (productionUrl > 0) {
      return productionSIO;
    } else return productionSIO;
  } catch (err) {
    console.log("GET CURRENT SOCKET ENDPOINT ", err);
    return "https://chat.getkart.com/";
  }
};

export {
  CURRENT_API_URL,
  CURRENT_SIO_URL
};