import dotenv from 'dotenv';
dotenv.config();
export const CONFIG = {
    local: {
        // WEB_URL: "http://localhost:3000",
        // API_URL: "http://localhost:9999",
        // BUCKET_URL: "http://localhost:3000",
        // MEDIA_URL: "http://localhost:3000",
        // SIO_ENDPOINT: "http://localhost:3000"
        WEB_URL: process.env.REACT_APP_LOCAL_WEB_URL,
        API_URL: process.env.REACT_APP_LOCAL_API_URL,
        BUCKET_URL: process.env.REACT_APP_BUCKET_BASE_URL,
        MEDIA_URL: process.env.REACT_APP_DEV_MEDIA_URL,
        SIO_ENDPOINT: process.env.REACT_APP_DEV_CHAT_SIO
    },
    development: {
        WEB_URL: process.env.REACT_APP_DEV_WEB_URL,
        API_URL: process.env.REACT_APP_DEV_API_URL,
        BUCKET_URL: process.env.REACT_APP_BUCKET_BASE_URL,
        MEDIA_URL: process.env.REACT_APP_DEV_MEDIA_URL,
        SIO_ENDPOINT: process.env.REACT_APP_DEV_CHAT_SIO
    },
    stage: {
        WEB_URL: process.env.REACT_APP_STAGE_WEB_URL,
        API_URL: process.env.REACT_APP_STAGE_API_URL,
        BUCKET_URL: process.env.REACT_APP_BUCKET_BASE_URL,
        MEDIA_URL: process.env.REACT_APP_STAGE_MEDIA_URL,
        SIO_ENDPOINT: process.env.REACT_APP_STAGE_CHAT_SIO
    },
    production: {
        WEB_URL: process.env.REACT_APP_PROD_WEB_URL,
        API_URL: process.env.REACT_APP_PROD_API_URL,
        BUCKET_URL: process.env.REACT_APP_BUCKET_BASE_URL,
        MEDIA_URL: process.env.REACT_APP_PROD_MEDIA_URL,
        SIO_ENDPOINT: process.env.REACT_APP_PROD_CHAT_SIO
    },
    beforeTokenString: process.env.REACT_APP_BEFORE_TOKEN_STR,
}