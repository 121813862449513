import { CURRENT_API_URL as API_BASE } from "./config/getCurrentURL";
import { getToken as token, getCSRFToken, machineId, removeUserSession } from "./utils/common";
import { CONFIG } from "./config/config.js";
import { notifyToast } from "./utils/toast.js";

const axios = require("axios");
const beforeTokenString = CONFIG.beforeTokenString;


const getCSRF = () => {
  const csrfCookie = document.cookie.split('; ').find(row => row.startsWith('XSRF-TOKEN'))?.split('=')[1];
  return csrfCookie || getCSRFToken();
}

const POST = async (url, data) => {
  url = API_BASE() + url;
  const headers = {
    "Content-Type": "application/json",
    // 'X-CSRF-Token': getCSRF(),
    authToken: beforeTokenString + token(),
    machineId: await machineId()
  };
  const reqOption = {
    method: "POST",
    url: url,
    data: data,
    headers: headers,
  };
  try {
    const postApiRes = await axios(reqOption);
    if (postApiRes?.data.status === 2 || postApiRes.status === 401) {
      removeUserSession();
      // window.location.pathname('/')
      window.location.reload();
      notifyToast("Session Expired!! Please Log in Again!", "error", "bottom-right", 2500)
      return
    }
    return postApiRes.data;
  } catch (err) {
    if (err?.response?.data?.status === 2) {
      removeUserSession();
      // window.location.pathname('/')
      window.location.reload();
      notifyToast("Session Expired!! Please Log in Again!", "error", "bottom-right", 2500)
      return
    }
    return tryCatch(err);
  }
};

const POSTWITHTOKEN = async (url, authToken, data) => {
  if (!authToken) {
    return;
  }
  const headers = {
    "Content-Type": "application/json",
    authToken: beforeTokenString + authToken,
    machineId: await machineId()
  };

  try {
    url = API_BASE() + url;
    let reqOption = {};
    reqOption.method = "POST";
    reqOption.url = url;
    reqOption.data = data;
    reqOption.headers = headers;
    const postApiRes = await axios(reqOption);
    return postApiRes.data;
  } catch (err) {
    return tryCatch(err);
  }
};

const GET = async (url, data) => {
  const headers = {
    "Content-Type": "application/json",
    authToken: beforeTokenString + token(),
    machineId: await machineId()
  };
  try {
    url = API_BASE() + url;
    const getResponse = await axios.get(url, {
      headers: headers,
      params: data,
    });
    return getResponse.data;
  } catch (err) {
    return tryCatch(err);
  }
};

const GETWITHPDFDOWN = async (url, data) => {
  const headers = {
    "Content-Type": "application/json",
    authToken: beforeTokenString + token(),
    machineId: await machineId()
  };
  try {
    url = API_BASE() + url;
    const getResponse = await axios.get(url, {
      responseType: 'arraybuffer',
      headers: headers,
      params: data,
    });
    return getResponse;
  } catch (err) {


    return tryCatch(err);
  }
};
/* When a client needs to replace an existing Resource entirely, then can use PUT. */
const PUT = async function (url, id, content) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authToken: beforeTokenString + token(),
      machineId: await machineId()
    },
  };
  try {
    url = API_BASE() + url + "/" + id;
    const getResponse = await axios.put(url, content, config);
    return getResponse.data;
  } catch (err) {
    return tryCatch(err);
  }
};

/*  When we're doing a partial update, then can use PATCH. */
const PATCH = async function (url, id, content) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authToken: beforeTokenString + token(),
      machineId: await machineId()
    },
  };
  try {
    url = API_BASE() + url + "/" + id;
    const getResponse = await axios.patch(url, content, config);
    return getResponse.data;
  } catch (err) {
    return tryCatch(err);
  }
};

const DELETE = async function (url, id) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      authToken: beforeTokenString + token(),
      machineId: await machineId()
    },
    // , data: {}
  };
  try {
    url = API_BASE() + url + "/" + id;
    const getResponse = await axios.delete(url, config);
    return getResponse.data;
  } catch (err) {
    return tryCatch(err);
  }
};

const UPLOAD = async function (url, fileData, buckeyPath = 'pickzon') {
  var formData = new FormData();
  for (let i = 0; i < fileData.length; i++) {
    formData.append("files", fileData[i]);
  }
  formData.append("bucketKey", buckeyPath);
  const headers = {
    "Content-Type": "multipart/form-data",
    authToken: beforeTokenString + token(),
    machineId: await machineId()
  };
  try {
    let baseURL = API_BASE()
    const postApiRes = await axios({
      method: "POST",
      url: baseURL + url,
      data: formData,
      headers: headers,
    });
    let res = postApiRes.data
    if (res.status === 1) {
      let payload = res.payload
      let urlImg = []
      for (let u = 0; u < payload.length; u++) {
        urlImg.push({ image: payload[u], alt: u })
      }
      res.payload = urlImg
    }
    return res;
  } catch (err) {
    return tryCatch(err);
  }
};
const POSTFORM = async (url, fileData) => {
  let formData = new FormData();

  formData.append("Images", fileData);
  const headers = {
    "Accept": "application/json",
    "Content-Type": "multipart/form-data",
    authToken: beforeTokenString + token(),
    machineId: await machineId()
  };
  try {
    let baseURL = API_BASE()
    const postApiRes = await axios({
      method: "POST",
      url: baseURL + url,
      data: formData,
      headers: headers,
    });
    return postApiRes.data;
  } catch (err) {
    return tryCatch(err);
  }
}
// For base 64
function createBase64(fileData) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      resolve(reader.result);
    };
  });
}

// const tryCatch = (err) => {
//   try {
//     if (
//       err.response.status === 400 ||
//       err.response.status === 401 ||
//       err.response.status === 500
//     ) {
//       return notifyToast(err.response.data?.message, "error", "bottom-right", 2500);
//     }
//     return notifyToast(err.response.data?.message, "error", "bottom-right", 2500);
//   } catch (er) {
//     return notifyToast("Server Down", "error", "bottom-right", 2500);
//   }
// };

const tryCatch = (err) => {
  try {
    if (
      err.response.status === 400 ||
      err.response.status === 401 ||
      err.response.status === 500
    ) {
      const { status, message, payload } = err.response?.data
      return { status: status, message: message, payload: payload }
    }
    // err.response.data;
    return { status: 0, message: "Server Down", payload: [] };
  } catch (er) {
    return { status: 0, message: "Server Down", payload: [] };
  }
};

export { POST, GET, PUT, PATCH, UPLOAD, DELETE, POSTWITHTOKEN, GETWITHPDFDOWN, POSTFORM, CONFIG };

// const { status, message, payload } = await POST('/test-post', { emailId: 'vish@gmail.com', password: '12345' })
// const { status, message, payload } = await GET('/test-get', { pageNumber: 0, pageLimit: 2 })
// const { status, message, payload } = await PUT('/test-put', "123", { emailId: 'vish@gmail.com', password: '12345' })
// const { status, message, payload } = await PATCH('/test-patch', 123123, { emailId: 'vish@gmail.com', password: '12345' })
// const { status, message, payload } = await DELETE('/test-delete', "oaidf123kjh23")
