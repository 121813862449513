import React, { memo, useCallback, useState } from 'react'
import { Divider, Form, FormField, Header, HeaderSubheader, Icon, Image, Segment } from 'semantic-ui-react'
import logo from '../../../../assets/images/written-logo.svg'
import SubmitButton from './SubmitButton'
import { generateMachineId, setCSRFToken, setUserSession } from '../../../../utils/common'
import { GET, POST } from '../../../../Services'
import { notifyToast } from '../../../../utils/toast'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const LoginModal = () => {
   const history = useHistory();
   const [showPass, setShowPass] = useState(false);
   const [loginState, setLoginState] = useState({ username: "", password: "" })

   const onHandleSubmit = useCallback(async () => {
      try {
         if (!loginState.password || !loginState.username) {
            return notifyToast("Field can't be empty", 'error', 'bottom', 2500);
         }
         const machineId = await generateMachineId();
         if (!machineId) return notifyToast("Can't detect machine id", 'error', 'bottom', 2500);


         // const { csrfToken } = await GET('/createcsrf', {})
         // setCSRFToken(csrfToken)
         // const csrfToken2 = await GET('/check', {})

         debugger;
         const { status, message, payload } = await POST('/v1/auth/login', {
            ...loginState,
            machineId: machineId
         });
         if (status === 2) {
            return notifyToast(message, 'error', 'bottom', 2500);
         } else if (status === 0) {
            return notifyToast(message, 'error', 'bottom', 2500);
         } else {
            setUserSession(payload.authToken, payload.data);
            if (payload.data.sideBarPanel.length > 0) {
               const redirectRouter = payload.data.sideBarPanel[0].url2;
               history.push(redirectRouter);
            } else {
               history.push('/dashboard');
            }
            window.location.reload();
         }
      } catch (error) {
         console.log("Login-onHandleSubmit", error);
      }
   }, [history, loginState])

   const onHandleChange = (e) => {
      const { name, value } = e.target;
      setLoginState((pre) => ({
         ...pre,
         [name]: value
      }))
   }

   return (<div style={{ ...style.wrapper }}>
      <Segment basic>
         <Image centered circular src={logo} size='medium' />
         <Header as='h1' style={style.heading}>
            Welcome Back ! <span role='img' aria-label='welcome-back'>👋</span>
            <HeaderSubheader as='h2' style={style.heading}>
               Login to dashboard...
            </HeaderSubheader>
         </Header>
         <Divider hidden />
         <Form>
            <FormField>
               <label style={style.textLabel}>Username</label>
               <input
                  name='username'
                  placeholder='Username'
                  style={style.textField}
                  onChange={(e) => onHandleChange(e)}
               />
            </FormField>
            <FormField>
               <label style={style.textLabel}>Password</label>
               <input
                  name='password'
                  type={showPass ? 'text' : 'password'}
                  placeholder='Password'
                  style={{ ...style.textField, position: "relative", paddingRight: "3rem" }}
                  onChange={(e) => onHandleChange(e)}
               />
               {loginState.password.length > 0 && <Icon
                  name={showPass ? 'eye slash' : 'eye'}
                  circular
                  style={style.maskIcon}
                  onClick={() => setShowPass(pre => !pre)}
               />}
            </FormField>
            {/* <FormField style={style.forgetText}>
               <span style={style.textLabel} onClick={() => isForget(true)}>Forget Password ?</span>
            </FormField> */}
            <SubmitButton
               label='Login'
               onHandleClick={onHandleSubmit}
            />
         </Form>
      </Segment>
   </div >)
}

export default memo(LoginModal);

const style = {
   heading: {
      color: "#fff",
      fontWeight: "600"
   },
   forgetText: {
      display: "flex",
      justifyContent: "center",
      textDecoration: "underline",
      textDecorationColor: "#fff",
      cursor: "pointer"
   },
   maskIcon: {
      position: "absolute",
      right: "0.5rem",
      top: "8.5rem",
      color: "#000",
      backgroundColor: "#fff",
      cursor: "pointer"
   },
   textLabel: {
      color: "#fff",
      fontSize: "16px",
   },
   textField: {
      border: "none",
      borderRadius: "2rem",
      backgroundColor: "#f0f0f0",
      color: "#000",
      fontSize: "16px",
      padding: "1rem"
   },
   wrapper: {
      width: "25%",
      padding: "2rem",
      backgroundColor: "#ffffff33",
      backdropFilter: "blur(20px)",
      border: "1px solid #ffffff26",
      borderRadius: "10px",
      boxShadow: "0 20px 40px #0000002e",
      // transform: "rotateY(180deg)"
      //    &.flipped {
      //       .front {
      //           transform: rotateY(180deg);
      //       }
      //       .back {
      //           transform: rotateY(0deg);
      //       }
      //   }
      //   .front,
      //   .back {
      //       box-sizing: border-box;
      //       height: 100%;
      //       width: 100%;
      //       display: block;
      //       background: $new-white;
      //       box-shadow: 10px 10px 5px rgb(95, 77, 99);
      //       padding: 10px;
      //       position: absolute;
      //       border-radius: 10px;
      //       backface-visibility: hidden;
      //       transform-style: preserve-3d;
      //       transition: -webkit-transform ease 500ms;
      //       transition: transform ease 500ms;
      //   }
      //   .front {
      //       z-index: 2;
      //       transform: rotateY(0deg);
      //       /* front tile styles go here! */
      //   }
      //   .back {
      //       background: $new-white;
      //       transform: rotateY(-180deg);
      //       padding: 20px;
      //       font-size: 20px;
      //       /* back tile styles go here! */
      //   }
   }
}