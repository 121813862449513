import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, } from "react-router-dom";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import ScrollToTop from "./utils/scrollToTop";
import Toast from "./utils/toast";
import { getToken } from "./utils/common";
import SideBarAdmin from "./adminPanel/leftSIdeDrawer/SideBarAdmin";

import LoginPage from './adminPanel/rightSIdeDrawer/Login/LoginPage.jsx'
import Navbar from "./adminPanel/component/Navbar/Navbar";
import NotFound from "./adminPanel/NotFound";

import PAGE_ROUTES from "./utils/redirects/pageRoutes.js";

const Main = () => {
  return (
    <>
      <Suspense
        fallback={
          <Dimmer
            active
            inverted>
            <Loader
              size="massive"
              inverted
              content={<h1>Loading...</h1>} />
          </Dimmer>
        }>
        <Router>
          <ScrollToTop />
          <Toast />
          {getToken() ? (
            <div className="panelContainer">
              <Navbar />
              <div className="leftSideDrawer">
                <div className="sidebar-admin">
                  <SideBarAdmin />
                </div>
              </div>
              <div className="rightSideDrawer">
                <Segment basic style={{
                  height: "100vh",
                  overflow: "auto",
                  width: "100%",
                  padding: "0 0.5rem"
                }}>
                  <Switch>
                    {PAGE_ROUTES.map((route, index) => (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        children={<route.main />}
                      />
                    ))}
                  </Switch>
                </Segment>
              </div>
            </div>
          ) : (
            <Switch>
              <Route path="/:anyurl" exact component={LoginPage} />
              <Route path="/" exact component={LoginPage} />
              <Route path="*" component={NotFound} />
            </Switch>
          )}
        </Router>
      </Suspense>
    </>
  );
}
export default Main;
