import React, { memo } from 'react'
import { Button } from 'semantic-ui-react'

const SubmitButton = ({ label = "Submit", onHandleClick }) => {

   return (
      <Button
         fluid
         style={style}
         onClick={onHandleClick}
      >{label}</Button>
   )
}

export default memo(SubmitButton);

const style = {
   marginTop: "2rem",
   border: "none",
   borderRadius: "2rem",
   backgroundColor: "#cd89ff",
   color: "#fff",
   fontSize: "16px",
   padding: "1rem"
}