import React from 'react'
import { Button, Header, Image, Segment } from 'semantic-ui-react';
import notFoundImage from '../assets/images/404.svg'
import { useHistory } from 'react-router-dom';

const NotFound = () => {
   const router = useHistory();
   return (<Segment style={{
      paddingTop: "5%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
   }}>
      <Image src={notFoundImage} size='large' centered />
      <Header as='h1' icon>
         Page Not Found
         <Header.Subheader>
            The page you are looking is might be broken or does not exist.
         </Header.Subheader>
         <Button onClick={() => router.push('/dashboard')} >
            Go back to Dashboard.
         </Button>
      </Header>
   </Segment >)
};

export default NotFound