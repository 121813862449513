import React from 'react'
import { Container } from 'semantic-ui-react'
import heroImage from '../../../assets/images/loginHero.jpg'
import LoginModal from './modals/LoginModal'
// import ForgetPasswordModal from './modals/ForgetPasswordModal'

const LoginPage = () => {
   // const [isForget, setIsForget] = useState(false)

   return (
      <Container fluid style={style}>
         <LoginModal />
         {/* {isForget ?
            <ForgetPasswordModal isForget={(value) => setIsForget(value)} /> :
            <LoginModal isForget={(value) => setIsForget(value)} />} */}
      </Container>
   )
}

export default LoginPage;

const style = {
   height: "100vh",
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   backgroundImage: `url(${heroImage})`,
   backgroundSize: "cover"
}