import { lazy } from "react";

//:::dashboard:::
const Dashboard = lazy(() => import("../../adminPanel/rightSIdeDrawer/Dashboard/Dashboard"));

const UserList = lazy(() => import("../../adminPanel/rightSIdeDrawer/Users/UserList"))
const ReferralUserList = lazy(() => import("../../adminPanel/rightSIdeDrawer/Users/ReferralUserList.jsx"))
const VerificationReq = lazy(() => import("../../adminPanel/rightSIdeDrawer/verificationRequests/VerificationRequests.jsx"))

const NotFound = lazy(() => import("../../adminPanel/NotFound"));

const ViewUser = lazy(() => import("../../adminPanel/rightSIdeDrawer/Users/user/UserView.jsx"));

//:::blogs:::
const AddBlog = lazy(() => import("../../adminPanel/rightSIdeDrawer/Blogs/AddBlog"));
const Bloglist = lazy(() => import("../../adminPanel/rightSIdeDrawer/Blogs/blogList"));
const BlogCategories = lazy(() => import("../../adminPanel/rightSIdeDrawer/Blogs/BlogCategories"));

const PAGE_ROUTES = [
   {
      path: "/",
      exact: true,
      main: () => <Dashboard />
   },
   {
      path: "/add-blog",
      main: () => <AddBlog />
   },
   {
      path: "/blog-list",
      main: () => <Bloglist />
   },
   {
      path: "/blog-categories",
      main: () => <BlogCategories />
   },
   {
      path: "/dashboard",
      main: () => <Dashboard />
   },
   {
      path: "/user-list",
      main: () => <UserList />
   },
   {
      path: "/referral-user-list",
      main: () => <ReferralUserList />
   },
   {
      path: "/view-user/:id",
      main: () => <ViewUser />
   },
   {
      path: "/verification-requests",
      main: () => <VerificationReq />
   },
   {
      path: "*",
      main: () => <NotFound />
   },
];
export default PAGE_ROUTES;