import React, { Fragment, useState } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Accordion, AccordionContent, AccordionTitle, Icon } from 'semantic-ui-react'
// import { getUser } from '../../../utils/common'

const MultiAccordion = ({ arr = [] }) => {
   const { pathname } = useLocation()
   // debugger
   const [isActive, setIsActive] = useState(0);
   return (
      <Accordion
         styled
         style={{
            width: "100%",
            borderRadius: 0
         }}>
         {arr.map((element, i) => {
            return (<Fragment key={i}>
               <AccordionTitle
                  style={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-between",
                     color: isActive === i ? "#fff" : "#000",
                     backgroundColor: isActive === i ? "#2185d0" : "#fff"
                  }}
                  // index={i}
                  active={isActive === i}
                  onClick={() => setIsActive(i)}>
                  <Link to={element.url2} style={{ color: "inherit", fontSize: isActive === i ? "16px" : "14px" }} >
                     <Icon name={element.icon} />
                     {element.title}
                  </Link>
                  {element?.content?.length > 0 && <Icon name={isActive === i ? "caret down" : "caret right"} />}
               </AccordionTitle>
               <AccordionContent
                  active={isActive === i && element?.content?.length > 0}
                  style={{
                     backgroundColor: isActive === i ? "#2185d0" : "#fff",
                     padding: "0 0.8em 0.8em"
                  }}>
                  <Accordion style={{
                     margin: 0,
                     textAlign: "left",
                  }}>
                     {element.content?.map((item, indics) => {
                        return (
                           <Link to={item.url} key={indics} style={{ color: "inherit", fontStyle: "italic" }} >
                              <AccordionTitle
                                 style={{
                                    color: pathname === item.url ? "#2185d0" : "#000",
                                    borderTop: "1px solid #ccc",
                                    fontSize: pathname === item.url ? "16px" : "14px",
                                    textDecoration: pathname === item.url ? "solid underline 2px" : "none",
                                    fontWeight: "bolder",
                                    paddingLeft: pathname === item.url ? "2rem" : ""
                                 }}>
                                 {/* <Icon name='dropdown' style={{ textDecoration: "none" }} /> */}
                                 {item.name}
                              </AccordionTitle>
                           </Link>)
                     })}
                  </Accordion>
               </AccordionContent>
            </Fragment>)
         })}
      </Accordion >)
}

export default MultiAccordion;